.dashboard{
    color:#494b74
}
.dashboard:hover{
    color:#3699FF;
}

.dashboard-text{
    color:#464E5F;
}
.dashboard-text:hover{
    color:"#FFFFFF"
}


.Finished{
    color:#494b74,
}
.Finished:hover{
    color:#3699FF;
}

.Finished-text{
    color:#464E5F;
}
.Finished-text:hover{
    color:"#FFFFFF"
}


.Unfinished{
    color:#494b74;

}
.Unfinished:hover{
    color:#3699FF;
}

.Unfinished-text{
    color:#464E5F;
    font-size: 12;
}
.Unfinished-text:hover{
    color:"#FFFFFF"
}

.sidebar::-webkit-scrollbar{
    display: none;
}

.contentScroll{
    height:70vh;
    position: relative;
    overflow-y: scroll;

}
.contentScroll::-webkit-scrollbar{
    display: none;
   
}
